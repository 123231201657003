// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  
  .App {
    text-align: center;
    margin-top: 50px;
  }
  `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,SAAS;IACT,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":["/* src/index.css */\nbody {\n    margin: 0;\n    font-family: Arial, sans-serif;\n    background-color: #f0f0f0;\n  }\n  \n  .App {\n    text-align: center;\n    margin-top: 50px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
